import React, { useState, useRef, useEffect } from 'react';
import { useScreenType, useScreenTypeUpdate } from "../../components/context/MixContext";
import { motion } from "framer-motion";
import { useIdleTimerContext } from 'react-idle-timer';

import MenuLink from '../MenuLink/MenuLink';


import './Menu.css';


const Menu = () => {

    // Screen type
    const getScreenType = useScreenType();
    const setScreenType = useScreenTypeUpdate();
    const _idletimer = useIdleTimerContext();

    useEffect(() => {
        window.scrollTo(0, 1);
        setScreenType('menu');
        _idletimer.start();
    }, []);


    return (
        <div id="menu" className="menu-wrap">
            <div className="menu-bg"></div>
            <motion.div className="title"
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: 0 }} 
                transition={{ delay: .1, duration: .5, type: "spring", damping: 10, mass: 0.75, stiffness: 100 }}
            >
                <h1>ELUCIREM™ Quiz</h1>
                <h2>Start the quiz by selecting one of the following areas:</h2>
            </motion.div>
            <div className="menu-links">
                <MenuLink indx="0" delay="0.1" />
                <MenuLink indx="1" delay="0.2"/>
                <MenuLink indx="2" delay="0.3"/>
                <MenuLink indx="3" delay="0.4"/>
                <MenuLink indx="4" delay="0.5"/>
            </div>
        </div>
    );
};

export default Menu;