import React, { useEffect, useState, useRef } from 'react';
import { useSwiper } from 'swiper/react';
import { renderFormattedText } from "../../../utils/helpers";
import smoothscroll from 'smoothscroll-polyfill';
import { useModuleIndex, useModuleIndexUpdate } from "../../../components/context/ModuleContext";
import { useProgress, useProgressUpdate, useScore, useScoreUpdate } from "../../../components/context/ProgressContext";
import { useVidPlaying, useVidPlayingUpdate } from "../../../components/context/MixContext";
import QuestionSummary from '../QuestionSummary/QuestionSummary';
import QuizForm from '../../QuizForm/QuizForm';
import Player from '../../Video/Player';
import { motion, useAnimationControls, useAnimation } from "framer-motion";

import './Question.css';

const Question = (props) => {
    smoothscroll.polyfill();

    const _swiper = useSwiper();

    const divAnimationControls = useAnimation()

    const [shuffledArray, setShuffledArray] = useState();

    const [qSubmitted, setQSubmitted] = useState(false);
    const [qComplete, setQComplete] = useState(false);
    const [optSelected, setOptSelected] = useState();
    const [correctCheck, setCorrectCheck] = useState();
    const [score, setscore] = useState(0);
    const [summeryMode, setSummaryMode] = useState(false);

    // Progress
    const getProgress = useProgress();
    // const setProgressState = useProgressUpdate();

    // Score
    const getScore = useScore();
    const setScore = useScoreUpdate();

    // module index
    const getModuleIndex = useModuleIndex();
    const setModuleIndex = useModuleIndexUpdate();

    // video playing
    const getVidPlay = useVidPlaying();
    const setVidPlay = useVidPlayingUpdate(false);

    const optdata = props.data;
    const optArray = props.options;
    const optIndicators = ["a", "b"];
    const unshuffled = [0, 1];
    const divRef = useRef(null);
    const summaryRef = useRef(null);
    let arr = [];

    const divAnimationVariants = {
        init: {
          y: 0,
        },
        anim: {
          y: -20,
        },
      }

    useEffect(() => {
        setSummaryMode( props.summary[0].summarymode )
        const hiliteSbmt_init = document.getElementById(`opt${getModuleIndex}-${props.qnumber}`);
        const elements_init = hiliteSbmt_init.getElementsByClassName("option-click");
        for(let x=0; x < elements_init.length; x++) {
            elements_init[x].classList.remove("option-disabled");
        }
      }, [])

    const handleOptClick = (e, _id) => {
        
        setOptSelected(e.target.getAttribute("data-selectid"));
        const hilite = document.getElementById(`opt${getModuleIndex}-${props.qnumber}`);
        // set selection highlite
        const elements1 = hilite.getElementsByClassName("option-click");
          Array.from(document.querySelectorAll('.option-click')).forEach(
            (el) => el.classList.remove('option-selected')
          );
          e.target.classList.add("option-selected");
          setQSubmitted(true);
          setTimeout(function() {
            const subBtn = document.getElementById(`submit-btn-${getModuleIndex}-${props.currq}`);
            subBtn.scrollIntoView({ behavior: "smooth", block: "end"});
          }, 200);
    }

    const handleLink = () => {
        window.open("https://www.guerbet.com/footer/legal-information", "_blank")
    }

    const handleSubmitClick = (e) => {
        // console.log(props.qnumber)
        setQSubmitted(false);
        setQComplete(true);
        const hiliteSbmt = document.getElementById(`opt${getModuleIndex}-${props.qnumber}`);
        // const elements2 = hiliteSbmt.getElementsByClassName("option-click");
        divRef.current.classList.add("show-answer");
        
        if ( summeryMode ) {
            summaryRef.current.classList.add("show-summary");
        }


        Array.from(document.querySelectorAll(`.optclick-${getModuleIndex}-${props.currq}`)).forEach(
            (el) => {
                el.classList.add("option-disabled");
            }
            
        );

        // for(let x=0; x < elements2.length; x++) {
        //     elements2[x].classList.add("option-disabled");
        // }

        if ( optSelected === optdata.answer ) {
            setCorrectCheck(true);
            props.progress(1, props.activeindex);
        } else {
            setCorrectCheck(false);
            props.progress(0, props.activeindex);
        }

        setTimeout(function() {
            const scrollid = document.getElementById("answer-wrap");
            const headerid = document.getElementById("header");
            window.scrollTo({
                top: divRef.current.offsetTop-headerid.offsetHeight,
                behavior: "smooth"
           });
            // console.log(headerid.offsetHeight);

        }, 200);
    }


    const calcScore = () => {
        
        // console.log(getProgress);
        
    }


    if (props.data.instance === "question") {

        return (

            <>
                {/* <div className="debug-wrap">
                    <div id="debug">Debug</div>
                </div> */}
                <div id={`question-${getModuleIndex}-${props.currq}`} className="question" data-summarytype={props.summary[0].mediavidtype}>
                    <motion.div className="color-bg"
                        initial={{ opacity: 1, scaleY: 0, originY: 0 }}
                        animate={{ opacity: 1, scaleY: 1 }} 
                        transition={{ delay: .1, duration: .5 }}>
                    </motion.div>
                    <div className="question-wrap">
                        <div className="quiz-title">
                            <h1>{props.title} – Question {props.qnumber}/{props.qtotal-1}</h1>
                        </div>
                        {renderFormattedText(props.data.question)}
                        <div className="option-wrap">
                            <div id={`opt${getModuleIndex}-${props.qnumber}`} className="options">
                                {optArray.map((item, index) => (
                                    <div key={`opt${index}`} className="option-parent">
                                        <div className='option'>
                                            <div className={`optNum ${optIndicators[index]}`}></div>
                                            { qComplete ? 
                                                <img src={require(`../../../assets/images/quiz/mod${getModuleIndex}/${optArray[index].imga}`)} width="100%" alt="Option" />
                                                :
                                                <img src={require(`../../../assets/images/quiz/mod${getModuleIndex}/${optArray[index].img}`)} width="100%" alt="Option" />
                                            }
                                            <div className={`option-click opt optclick-${getModuleIndex}-${props.currq}` } onClick={(event) => handleOptClick(event, optArray[index].idx)} data-selectid={optArray[index].idx}></div>
                                        </div>
                                        <div className="mobile-footnotes">
                                            { qComplete ?
                                                <div>{renderFormattedText(optArray[index].imgfootnote)}</div>
                                                :
                                                <>
                                                    <p>&nbsp;</p>
                                                </>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footnote">
                    <div className="footnote-wrap">
                        <div className="footnote-holder">
                            {optArray.map((item, index) => (
                                <div key={`fnt${index}`} className="footnote-parent">
                                { qComplete ?
                                    <div>{renderFormattedText(optArray[index].imgfootnote)}</div>
                                    :
                                    <>
                                        <p>&nbsp;</p>
                                    </>
                                }  
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className={`footnote-${getModuleIndex} globalFootnote`}>
                    <div className="globalFootnoteCopy">
                        <span class="footenoteCNSCopy">Phase III PICTURE Trial, in CNS,</span><span class="footenoteBodyCopy">Phase III PROMISE Trial, in body,</span> demonstrated that gadopiclenol at a dose of 0.05 mmol/kg was noninferior to gadobutrol at a dose of 0.1 mmol/kg in terms of lesion visualization.
                    </div>
                    <div className="globalFootnoteCopy">
                        The majority of patients had the same number of lesions reported on precontrast and paired images with gadopiclenol compared with gadobutrol.
                    </div>
                </div>

                { props.data.refindicate != "" ?
                    
                    <div className="refs-sum">
                        <div className="refs-wrap">
                            { qComplete ?
                                <div className="refsum">
                                    <img src={require(`../../../assets/images/quiz/indicator.png`)} width="18" height="18" alt="Indicator" />
                                    { props.data.refindicate && renderFormattedText(props.data.refindicate)}
                                </div>
                                : null
                            }
                        </div>
                    </div>
                
                    :
                    null
                }

                <div ref={divRef} className="answer">
                    <div className="answer-wrap">
                        <div className="answer-content">
                            <div className="answer-copy">
                                <div className="fdbk-img">
                                    {  correctCheck ?
                                        <img src={require(`../../../assets/images/quiz/correct.png`)} width="100%" alt="Option" />
                                        :
                                        <img src={require(`../../../assets/images/quiz/incorrect.png`)} width="100%" alt="Option" />
                                    }
                                </div>
                                { correctCheck ? renderFormattedText(props.data.correct) : renderFormattedText(props.data.incorrect) }
                            </div>
                            
                        </div>
                    </div>

                </div>


                { summeryMode === true ? 
                    <>
                        <div ref={summaryRef} className="summary">
                            <div className="summary-wrap">
                                <div className="answer-content">
                                    <div className="summary-content">
                                        <div className="summary-copy">
                                            <QuestionSummary content={props.summary[0]} />
                                            { props.summary[0].mediavidtype ? 
                                                <Player />
                                            :
                                                <img src={require(`../../../assets/images/quiz/mod${getModuleIndex}/summary.png`)} width="100%" alt="Option" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="img-footnote">
                                {renderFormattedText(props.summary[0].footnote)}
                            </div> */}
                        </div>
                    </>
                : null }


                <div id={`submit-btn-${getModuleIndex}-${props.currq}`} className={qSubmitted ? "submit-wrap" : "submit-wrap submit-dis"}>
                    <div className="submit-parent">
                        <div className="submit" onClick={handleSubmitClick}>Submit</div>
                    </div>
                </div>

                <div className={qComplete ? "next-wrap" : "next-wrap next-dis"}>
                    <div className="next-parent">
                        <div className="next" onClick={() => props.slidenextplease(_swiper)}>Next</div>
                    </div>
                </div>

                { props.data.refs != null ?
                    
                        <div className="refs">
                            <div className="refs-wrap">
                                { qComplete ?
                                    <>
                                        { props.data.refsa && renderFormattedText(props.data.refsa)}
                                    </>
                                    :
                                    <>
                                        {props.data.refs && renderFormattedText(props.data.refs)}
                                    </>
                                }
                            </div>
                        </div>
                    
                    :
                    null
                }
                

            </>

        );

        

    }

    if (props.data.instance === "result") {

        console.log(props.title)
        return (

            <div className="result">
                <div className="result-wrap">
                    <div className="trophy">
                        <img src={require(`../../../assets/images/quiz/trophy.png`)} width="100%" alt="trophy" />
                    </div>
                    <div className="result-feedback">
                        <p>Thank you for taking the ELUCIREM™ {props.title} quiz</p>

                        <h1>Your final score is {getScore[getModuleIndex]}/{props.qtotal-1} </h1>
                        
                        <p>To keep up to date with the latest news and developments on ELUCIREM™ please provide your details</p>
                        {/* <QuizForm /> */}
                        <iframe className="form-iframe" src="/guerbet-form/" width="100%" height="100%" frameBorder="0"></iframe>
                        <p className="register">By registering, you agree that your personal data will be processed by Guerbet, its affiliates, and its authorized third parties for the purposes of providing commercial and scientific information related to the field of radiology. The legal basis for processing your personal data is your consent. They will be stored until you withdraw your consent using the unsubscription link found at the bottom of Guerbet’s emails. You have the right to request access, rectification, or erasure on your personal data and to request restriction of processing by contacting Guerbet at <a href="mailto:diagnostic.imaging@guerbet-communication.com?subject=Access restriction request" className="link-out">diagnostic.imaging@guerbet-communication.com</a>.</p>
                    </div>
                </div>
            </div>

        );

    }


};

export default Question;