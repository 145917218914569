import React, {useEffect, useState, useRef} from 'react';

import { resetProgress } from "../../utils/hooks";

// import { useModal } from "../../components/context/ModalContext";
// import { useModalUpdate } from "../../components/context/ModalContext";
import { useData, useDataUpdate } from "../../components/context/DataContext";
import { useProgress, useProgressUpdate, useScore, useScoreUpdate } from "../../components/context/ProgressContext";
import { useScreenShow, useScreenShowUpdate } from "../../components/context/ScreenContext";
import { useModuleIndex, useModuleIndexUpdate } from "../../components/context/ModuleContext";
import { useModalUpdate, useModalContentUpdate } from "../../components/context/ModalContext";
import { useZoomFactor, useZoomFactorUpdate, useVidPlaying, useVidPlayingUpdate } from "../../components/context/MixContext";

// import { useIdleTimerContext } from 'react-idle-timer';


import { 
    IdleTimerProvider, 
    IdleTimerConsumer, 
    IIdleTimerContext, 
    IdleTimerContext,
    useIdleTimerContext
  } from 'react-idle-timer'

import Menu from '../../components/Menu/Menu';
import Quiz from '../../components/Quiz/Quiz';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Isi from '../Isi/Isi';

import './Content.css';



const Content = (props) => {

    const _idletimer = useIdleTimerContext();
    // _idletimer.on('onIdle', function(){
        // console.log(_idletimer.isIdle)
    // });

    // const onPresenceChange = (presence) => {
    //     // console.log("efwef");
    //     // Handle state changes in one function
    //   }
    
    //   const onPrompt = () => {
    //     // Fire a Modal Prompt
    //   }
    
    //   const onIdle = () => {
    //     console.log("Idle");
    //     // Close Modal Prompt
    //     // Do some idle action like log out your user
    //   }
    
    //   const onActive = (event) => {
    //     console.log("efwef");
    //     // Close Modal Prompt
    //     // Do some active action
    //   }
    
    //   const onAction = (event) => {
    //     // console.log("efwef");
    //     // Do something when a user triggers a watched event
    //   }
    
    //   const {
    //     start,
    //     reset,
    //     activate,
    //     pause,
    //     resume,
    //     isIdle,
    //     isPrompted,
    //     isLeader,
    //     isLastActiveTab,
    //     getTabId,
    //     getRemainingTime,
    //     getElapsedTime,
    //     getLastIdleTime,
    //     getLastActiveTime,
    //     getIdleTime,
    //     getTotalIdleTime,
    //     getActiveTime,
    //     getTotalActiveTime
    //   } = useIdleTimer({
    //     onPresenceChange,
    //     onPrompt,
    //     onIdle,
    //     onActive,
    //     onAction,
    //     timeout: 1000*3,
    //     promptBeforeIdle: 0,
    //     events: [
    //     //   'mousemove',
    //     //   'keydown',
    //     //   'wheel',
    //     //   'DOMMouseScroll',
    //     //   'mousewheel',
    //       'mousedown',
    //     //   'touchstart',
    //     //   'touchmove',
    //     //   'MSPointerDown',
    //     //   'MSPointerMove',
    //     //   'visibilitychange',
    //     //   'focus'
    //     ],
    //     immediateEvents: [],
    //     debounce: 0,
    //     throttle: 0,
    //     eventsThrottle: 200,
    //     element: document,
    //     startOnMount: true,
    //     startManually: true,
    //     stopOnIdle: false,
    //     crossTab: false,
    //     name: 'idle-timer',
    //     syncTimers: 0,
    //     leaderElection: false
    //   })




    const initSeconds = 5;
    let seconds = initSeconds;
    let intervalID;
    let time;
    let isPlaying = false;

    const setModalIndex = useModalContentUpdate();
    const setModalState = useModalUpdate();

    // const json = useData();
    const getData = useData();
    const setDataState = useDataUpdate();
    const [data, setData] = useState();
    const [vidMode, setVidMode] = useState();

    // Progress
    const getProgress = useProgress();
    const setProgressState = useProgressUpdate();

    // Screen show
    const getScreenShow = useScreenShow();
    const setScreenShow = useScreenShowUpdate();

    // module index
    const getModuleIndex = useModuleIndex();
    const setModuleIndex = useModuleIndexUpdate();

    // Score
    const getScore = useScore();
    const setScore = useScoreUpdate();

    // Zoom
    const getZoom = useZoomFactor();
    const setZoom = useZoomFactorUpdate();

    // // video playing
    const getVidPlay = useVidPlaying();
    const setVidPlay = useVidPlayingUpdate();




    window.addEventListener('resize', () => {
        const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
        setZoom(browserZoomLevel);
    })
    

    useEffect(() => {

        fetch('./content.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
    .then(function(response){
        return response.json();
    })
    .then(function(dataJson) {
        setData(dataJson);
        setDataState(dataJson);
        setProgressState(resetProgress(dataJson.quiz));
        setScreenShow(true);


        const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
        setZoom(browserZoomLevel);

        window.scrollTo(0, 2);


        // const inactivityTime = () => {
        //   window.onload = () => resetTimer(dataJson.quiz);
        //   document.onload = () => resetTimer(dataJson.quiz);
        //   document.onmousemove = () => resetTimer(dataJson.quiz);
        //   document.onmouseover = () => resetTimer(dataJson.quiz);
        //   document.onmousedown = () => resetTimer(dataJson.quiz); // touchscreen presses
        //   document.ontouchstart = () => resetTimer(dataJson.quiz);
        //   document.onclick = () => resetTimer(dataJson.quiz); // touchpad clicks
        //   document.onkeypress = () => resetTimer(dataJson.quiz);
        // //   document.addEventListener('scroll', resetTimer, true); // improved; see comments
        // };
        // inactivityTime();
    });

    }, []) 

    
    const getSwiper = (_swiper) => {
        // const index_currentSlide = _swiper.realIndex;
        // const currentSlide = _swiper.slides[index_currentSlide].querySelector('.question');
        // const attr = currentSlide.getAttribute('data-summarytype');
        // console.log(attr);
        // setVidPlay(attr);
    }

    const onActive = (event) => {
        // Close Modal Prompt
        // Do some active action
        // console.log('onActive');
    }

    const onIdle = (onIdle) => {
        // console.log('NEW onIdle');
        logout();
    }



    const logout = () => {
            setScreenShow(true);
            resetScore(getData.quiz);
    }

    const resetScore = (_qarr) => {
        const tmparr = _qarr
        const arr = [];
        const tmpScoreArr = [];
        if ( tmparr.length !== undefined ) {
        tmparr.map((item, index) => {
            {
                let optArray = [];
                tmpScoreArr.push(0);
                item.questions.map((item, i, {length}) => {
                    if (length - 1 === i) {
                        // last one
                    } else {
                        optArray.push(null);
                    }
                });
                arr.push(optArray);
            }
        })
        setProgressState(arr);
        setScore(tmpScoreArr);
        }
    }


    if (!data) {
        return (
            <div className="loading-elem">
                <p>Loading data...</p>;
            </div>
        )
    }


    return (
        <div className="App">
            <IdleTimerProvider 
            events= {[
                'mousemove',
                'keydown',
                'wheel',
                'DOMMouseScroll',
                'mousewheel',
                'mousedown',
                'touchstart',
                'touchmove',
                'MSPointerDown',
                'MSPointerMove',
                'visibilitychange',
                'focus'
            ]}
             timeout={1000 * 60 * 5} startManually={true} onIdle={onIdle} onActive={onActive}>
                <Header />
                <main className={getScreenShow ? "container" : "container-white"}>
                    
                    <div id="content-wrap" className="content-wrap">
                        <div id="content" className="content">
                            {getScreenShow ? <Menu /> : <Quiz swipefunc={getSwiper} /> } 
                        </div>
                    </div>
                </main>
                <Isi />
                <Footer />
            </IdleTimerProvider>
        </div>
        );
    };
    
    export default Content;