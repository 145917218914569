import React from 'react';  

import { useModal, useModalUpdate, useModalContentUpdate } from "../../components/context/ModalContext";
import { useScreenShow, useScreenShowUpdate } from "../../components/context/ScreenContext";

import './Footer.css'

const Footer = () => {

    // Screen show
    const getScreenShow = useScreenShow();

    // const modalState = useModal(true);
    const setModalState = useModalUpdate();
    const popupModalSet = useModalUpdate(true);
    const setModalIndex = useModalContentUpdate();

    const handleClick = (_idx) => {
        setModalIndex(_idx);
        popupModalSet(true);
    }



    return (
        <footer className={!getScreenShow ? "footer-dark" : null }>
            <div className="footer-wrap">
                <div className={getScreenShow ? "footer-button-wrap" : "footer-hidden" }>
                    <div className="footer-btns">
                        <div className="footer-btn" onClick={() => handleClick(0)}>Phase III PICTURE trial design in CNS MRI</div>
                        <div className="footer-btn" onClick={() => handleClick(1)}>Phase III PROMISE trial design in body MRI</div>
                    </div>
                    <div className="footer-btns">
                        <div className="footer-btn new-user"onClick={(e) => handleClick(3)}>New user</div>
                    </div>
                </div>
                <div className="footer-footnote">
                    <div className="footer-logo">
                        <img src={require("../../assets/images/guerbet-logo.png")} width="192" alt="Guerbet logo" />
                    </div>
                    <div className="footer-text">
                        {/* <p><a href="https://www.elucirem-global.com/media/x41o4k41/mentions-l%C3%A9gales-abr%C3%A9g%C3%A9es-corporate-elucirem.pdf" target="_blank">Click here</a> for the SmPC. For more information, please refer to your local product information.</p> */}
                        {/* <p>This information is intended for EU HCPs only. Indications and presentations may differ from country to country. For complete information, please refer to your local SmPC.</p> */}
                        {/* <p>Results from case studies are not necessarily predictive of results in other cases. Other clinical studies are needed or should be performed to confirm the result. Trademarks are the property of their respective owners.</p> */}
                        <p className="jobcode">GU07240129 | Date of preparation: May 2024</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;