import React, { useState, useRef, useEffect } from 'react';
import { useData } from "../../components/context/DataContext";
import { useProgress, useProgressUpdate, useScore, useScoreUpdate } from "../../components/context/ProgressContext";
import { useModuleIndex, useModuleIndexUpdate } from "../../components/context/ModuleContext";
import { useScreenType, useScreenTypeUpdate, useZoomFactor, useZoomFactorUpdate, useVidPlaying, useVidPlayingUpdate } from "../../components/context/MixContext";
import { Virtual } from 'swiper/modules';
// import Swiper core and required modules
import { Navigation, EffectFlip, EffectFade, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Question from './Question/Question';
import { motion } from "framer-motion";
import { useIdleTimerContext } from 'react-idle-timer';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/virtual';
import 'swiper/css/effect-flip';
import 'swiper/css/effect-fade';

import './Quiz.css'



const Quiz = (props) => {

    // Screen type
    const getScreenType = useScreenType();
    const setScreenType = useScreenTypeUpdate();

    // Progress
    const getProgress = useProgress();
    const setProgressState = useProgressUpdate();

    // Score
    const getScore = useScore();
    const setScore = useScoreUpdate();

    // module index
    const getModuleIndex = useModuleIndex();
    const setModuleIndex = useModuleIndexUpdate();

    // Zoom
    const getZoom = useZoomFactor();
    const setZoom = useZoomFactorUpdate();

    // video playing
    const getVidPlay = useVidPlaying();
    const setVidPlay = useVidPlayingUpdate();

    const _idletimer = useIdleTimerContext();
    _idletimer.start();
    

    const [activeIndex, setActiveIndex] = useState(0);
    const json = useData();
    const qArray = json && json.quiz[getModuleIndex].questions;

    const progressUpdate = (_n, _p) => {
        getProgress[getModuleIndex][_p] = _n;
        setProgressState(getProgress);
    }

    useEffect(() => {
        window.scrollTo(0, 2);
    }, []);


    const swipeChange = () => {
        // const answerfdbk = document.getElementsByClassName("answer");
        // const summaryfdbk = document.getElementsByClassName("summary");
        // for(let x=0; x < answerfdbk.length; x++) {
        //     answerfdbk[x].classList.remove("show-answer");
        //     summaryfdbk[x].classList.remove("show-answer");
        // }
        setVidPlay([false, null]);
    }





    const  hasScrollbar = () => {
        const body = document.body;
        const hasVerticalScrollbar = body.scrollHeight > body.clientHeight;
        


        const html = document.documentElement;
        const hasVerticalScrollbarHtml = html.scrollHeight > html.clientHeight;
    

        return hasVerticalScrollbar || hasVerticalScrollbarHtml;
    }
    

    const nextSlidePlease = (_swipe) => {
        console.log(hasScrollbar())

        if ( getZoom < 100 ) {
            const answerfdbk = document.getElementsByClassName("answer");
            const summaryfdbk = document.getElementsByClassName("summary");
            for(let x=0; x < answerfdbk.length; x++) {
                answerfdbk[x].classList.remove("show-answer");
                if ( summaryfdbk[x] ) {
                    
                    summaryfdbk[x].classList.remove("show-summary");
                }
            }
            _swipe.slideNext();
        } else {

            if(hasScrollbar()) {
                scrollToPosition(window, 0).then(() => {
                    // do something here
                    const answerfdbk = document.getElementsByClassName("answer");
                    const summaryfdbk = document.getElementsByClassName("summary");
                    for(let x=0; x < answerfdbk.length; x++) {
                        answerfdbk[x].classList.remove("show-answer");
                        if ( summaryfdbk[x] ) {
                            
                            summaryfdbk[x].classList.remove("show-summary");
                        }
                    }
                    _swipe.slideNext();
                });
            } else {
                _swipe.slideNext();
            }

        }


    }

  

    async function scrollToPosition(container, position) {
        position = Math.round(position);
      
        if (container.scrollTop === position) {
          return;
        }
      
        let resolveFn;
        let scrollListener;
        let timeoutId;
      
        const promise = new Promise(resolve => {
          resolveFn = resolve;
        });
      
        const finished = () => {
          container.removeEventListener('scroll', scrollListener);
          resolveFn();
        };
      
        scrollListener = () => {
          clearTimeout(timeoutId);
      
          // scroll is finished when either the position has been reached, or 100ms have elapsed since the last scroll event
          if (container.scrollTop === position) {
            finished();
          } else {
            timeoutId = setTimeout(finished, 100);
          }
        };
      
        container.addEventListener('scroll', scrollListener);
      
        container.scrollTo({
          top: position,
          behavior: 'smooth',
        });
      
        return promise;
      }

    //   const checkForVideo = (_swiper) => {
    //     const index_currentSlide = _swiper.realIndex;
    //     const currentSlide = _swiper.slides[index_currentSlide].querySelector('.question');
    //     const attr = currentSlide.getAttribute('data-summarytype') === "true" ? true : false;
    //     return attr;
    //   }

      



    return (
        <motion.div id="quiz" className="quiz"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }} 
            transition={{ delay: .2, duration: .5 }}>
            <div className="swiper">

                <Swiper

                observer={true}
                observeParents={true}
                autoHeight= {true}
                modules={[Navigation, EffectFade, EffectFlip, A11y, Virtual]}
                spaceBetween={2}
                slidesPerView={1}
                noSwiping={true}  
                noSwipingClass='noswiper'
                centeredSlides
                onSlideChange={(swiper) => {
                    console.log(swiper);
                    setActiveIndex(swiper.activeIndex);
                    swipeChange();
                    const index_currentSlide = swiper.realIndex;
                    props.swipefunc(swiper, swiper.slides[index_currentSlide].querySelector('.vjs-tech')); 

                }}

                onAfterInit={(swiper) => {
                    const index_currentSlide = swiper.realIndex;
                    props.swipefunc(swiper, swiper.slides[index_currentSlide].querySelector('.vjs-tech')); 
                }}

                onBeforeSlideChangeStart={(swiper) => {
                    scrollToPosition(window, 0).then(() => {
                        // do something here
                        
                    });
                }}

                onReachEnd={(swiper) => {
                    let tmpArr = [];
                    getProgress.map((items, index) => {
                        let tmpScore = 0;
                        items.map((subItems, sIndex) => {
                            if (subItems !== null) {
                                tmpScore += subItems;
                            }
                        })
                        tmpArr.push(tmpScore);
                      })
                      setScore(tmpArr);
                      setScreenType("results")
                }}
                >
                {qArray.map((item, index) => (
                    <SwiperSlide className="noswiper" key={index} virtualIndex={index}>
                        <Question quizplay={props.quizplay} key={index} currq={index} slidenextplease={nextSlidePlease} moduleindex={getModuleIndex} activeindex={activeIndex} title={json && json.quiz[getModuleIndex].title} qnumber={activeIndex+1} qtotal={json.quiz[getModuleIndex].questions.length} data={json && json.quiz[getModuleIndex].questions[index][0]} progress={ progressUpdate } options={json && json.quiz[getModuleIndex].questions[index][0].options.sort(() => Math.random() - 0.5)} summary={json && json.quiz[getModuleIndex].questions[index][0].summary} />
                    </SwiperSlide>
                ))}

                </Swiper>
            </div>
            
        </motion.div>
    );
};

export default Quiz;